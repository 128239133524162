import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import "../utils/utils.css"
import { ContentfulStayInTouchSectionData, PaginatedList } from '../types/types'
import ResponsiveImage from '../components/responsiveImage/ResponsiveImage'
import styles from './app.module.css'

interface IImage {
  file: { url: string }
}

interface IProps {
  data: {
    allContentfulStayInTouchSection: PaginatedList<ContentfulStayInTouchSectionData>
    appStore: PaginatedList<IImage>
    googleStore: PaginatedList<IImage>
  },

}

function MobileApp(props: IProps) {
  const data = props.data.allContentfulStayInTouchSection.nodes[0]
  const appStoreImageUrl = props.data.appStore.nodes[0].file.url
  const googleStoreImageUrl = props.data.googleStore.nodes[0].file.url

  useEffect(() => {
    if (navigator.userAgent.toLowerCase().includes("android")) {
      window.location.replace(data.googleMarketUrl)
    } else if (navigator.userAgent.toLowerCase().includes("iphone")) {
      window.location.replace(data.iosUrl)
    }
  }, []);

  return <div style={{
    position: "absolute",
    top: "50%",
    width: "80%",
    margin: "auto",
    transform: "translateY(-50%) translateX(15%)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignContent: "center",
  }}>
    <span className="fontH4" style={{ textAlign: "center" }}>{data.downloadTitle}</span>
    <br />
    <span className="fontB2">{data.downloadDescription}</span>
    <br />
    <div style={{
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignContent: "center",
    }}>
      <a href={data.iosUrl} target="_blank" rel="noopener noreferrer">
        <ResponsiveImage variants={[{ url: appStoreImageUrl, width: 150 }]} alt="App Store" className={styles.storeImage} />
      </a>
      <a href={data.googleUrl} target="_blank" rel="noopener noreferrer">
        <ResponsiveImage variants={[{ url: googleStoreImageUrl, width: 150 }]} alt="Google Play" className={styles.storeImage} />
      </a>
    </div>
  </div>
}

export default MobileApp

export const mobileAppQuery = graphql`
  query MobileAppQuery {
    allContentfulStayInTouchSection {
      nodes {
        downloadTitle
        downloadDescription
        iosUrl
        googleUrl
        googleMarketUrl
      }
    }
    appStore: allContentfulAsset(filter: { title: { eq: "appStore" } }) {
      nodes {
        file {
          url
        }
      }
    }
    googleStore: allContentfulAsset(filter: { title: { eq: "googlePlay" } }) {
      nodes {
        file {
          url
        }
      }
    }
  }
`